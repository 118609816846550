import PropTypes from 'prop-types'
import 'styles/globals.css'
import { withUrqlClient } from 'next-urql'
import { cacheExchange, dedupExchange, fetchExchange } from 'urql'
import { Provider } from 'react-redux'
import { store } from 'store'

const MyApp = ({ Component, pageProps }: { Component: React.ElementType, pageProps: object }) => {
  return (
    <Provider store={store}>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <Component {...pageProps} />
    </Provider>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object,
}

export default withUrqlClient((ssrExchange, _) => ({
  url: process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL!,
  exchanges: [dedupExchange, cacheExchange, ssrExchange, fetchExchange],
}),
{
  ssr: true,
},
)(MyApp)
