import { createSlice } from '@reduxjs/toolkit'

export const bookingRequestSlice = createSlice({
  name: 'bookingRequest',
  initialState: {
    arrival: null,
    departure: null,
    adults: 0,
    children: 0,
    availability: null,
  },
  reducers: {
    setArrival: (state, action) => {
      state.arrival = action.payload
    },
    setDeparture: (state, action) => {
      state.departure = action.payload
    },
    setAdults: (state, action) => {
      state.adults = action.payload
    },
    setChildren: (state, action) => {
      state.children = action.payload
    },
  },
})

export const { setArrival, setDeparture, setAdults, setChildren } = bookingRequestSlice.actions

export default bookingRequestSlice.reducer
