import { configureStore } from '@reduxjs/toolkit'
import bookingRequestReducer from 'store/booking-request'

export const store = configureStore({
  reducer: {
    bookingRequest: bookingRequestReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
